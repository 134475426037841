<template>
  <div id="home">

    <div id="div-header">
      <div style="width: 10px"></div>
      <img style="width: 52px;height: 52px;margin-right: 4px" src="../assets/ic_launcher_trans.png"/>
      <h2 id="h2">GodCode Apps</h2>
      <h4 id="h2" @click="privacy">Privacy</h4>
      <a target="_blank" style="background: transparent;color: white; text-decoration: none;"
         href="https://qm.qq.com/cgi-bin/qm/qr?k=eRH9HvvCGMm4QA_FOhTX5iV2Y5_LfGNg&jump_from=webapi&authKey=o3ys71XjR0+pnVKzXTulevIrJ9Eg6VFUca7sPEFD9RYjzrY8UMXP6WcI5Lx08tcL">
        <h4>
          Contact
        </h4>
      </a>
    </div>

    <h1 style="left: 0">木鱼-念经神器</h1>
    <div>
            <img id="img-store" src="../assets/apk-zh.png" @click="openApkZh()">
      <img id="img-store" src="../assets/app-store.png" @click="openAppStore()">
<!--      <img id="img-store" src="../assets/google-play.png" @click="openGooglePlay()">-->
      <!--      <img id="img-store" src="../assets/windows.jpg" style="border-radius: 6px" @click="openWindows()">-->
      <!--      <img id="img-store" src="../assets/web.jpg" style="border-radius: 6px"-->
      <!--           @click="openExternalLink('https://fish.firefix.cn/')">-->

    </div>

    <div id="div-body">
      <div id="div-screen">
        <img :id="isMobile?'mobile-screen-img':'screen-img'" src="../assets/screen1.jpeg">
        <img :id="isMobile?'mobile-screen-img':'screen-img'" src="../assets/screen2.jpeg">
      </div>
      <p id="p" v-if="!isMobile">{{ desc }}
      </p>
      <div v-if="!isMobile">
        <img src="../assets/weixinqr.jpg" style="width: 100px;height: 100px">
        <p id="p">微信扫一扫 </p>
      </div>
      <div v-if="!isMobile">
        <img src="../assets/qrcode.svg" style="width: 100px;height: 100px;background-color: white">
        <p id="p">抖音扫一扫 </p>
      </div>

    </div>
    <p id="mobile-p" v-if="isMobile">{{ desc }}
    </p>
    <div style="display: flex;flex-direction: row;align-items: center;justify-content: center" v-if="isMobile">
      <div>
        <img src="../assets/weixinqr.jpg" style="width: 100px;height: 100px">
        <p id="mobile-p">微信扫一扫 </p>
      </div>
      <span style="width: 20px"></span>
      <div>
        <img src="../assets/qrcode.svg"
             style="width: 100px;height: 100px;background-color: white;">
        <p id="mobile-p">抖音扫一扫 </p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'SubTitle',
  props: {
    msg: String
  },
  data() {
    return {
      isMobile: false,
      desc: "木鱼-念经神器是官网最新推出的一款佛教静修APP，作为一款佛教修炼软件，木鱼App致力于为用户提供人性化的智能敲击服务，让你迅速摆脱城市的喧嚣，快速迈入天人合一的境界，现代都市人民必备静修软件，赶快下载试试吧！GodCode应用程序开发团队成员都拥有丰富的经验和卓越的技术素养，能够开发出最具前瞻性和实用性的应用程序。我们将不断推出创新产品，不断挑战自我，满足用户需求，为数字时代的生活创造更多可能性。"
    }
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    openGooglePlay() {
      let url = 'https://play.google.com/store/apps/details?id=com.xhx.woodenfishs'
      this.openExternalLink(url)
    },
    openAppStore() {
      let url = 'https://apps.apple.com/cn/app/fastaichat/id6450999306'
      this.openExternalLink(url)
    },
    openWindows() {
      var a = document.createElement('a')
      a.download = name || 'apk'
      a.href = 'https://img.firefix.cn/chat/chatgpt_windows.zip';
      a.click();
    },
    openApkZh() {
      var a = document.createElement('a')
      a.download = name || 'apk'
      a.href = 'https://img.firefix.cn/downloaderx/apk/muyu.apk';
      a.click();
    },
    privacy() {
      window.location.href = "https://img.firefix.cn/fish/privacy.html"
    },
    openExternalLink(url) {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('micromessenger') !== -1) {
        // 在微信中打开外部链接
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        // 在其他浏览器中打开外部链接
        window.open(url);
      }
    },
    showToast(msg, duration) {
      duration = isNaN(duration) ? 3000 : duration;
      var m = document.createElement('div');
      m.innerHTML = msg;
      m.style.cssText = "width:50%; min-width:40px; background:#000; opacity:0.7; text-align:center; height:auto;min-height: 50px; color:#fff; line-height:50px; text-align:center; border-radius:4px; position:fixed; top:50%; left:20%; right:20%; z-index:999999; font-size: medium;";
      document.body.appendChild(m);
      setTimeout(function () {
        var d = 0.5;
        m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in';
        m.style.opacity = '0';
        setTimeout(function () {
          document.body.removeChild(m)
        }, d * 1000);
      }, duration);
    }
  }

}
</script>
<style scoped>
#home {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;

}

#img-bg {
  width: 0%;
  object-fit: contain;
  background: transparent;
}

#div-body {
  display: flex;
  width: 100vw;
  flex-direction: row;
}

#div-header {
  display: flex;
  width: 100vw;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

#h2 {
  margin-right: 0.8rem
}

#div-screen {
  margin-top: 1.6rem;
}

#mobile-screen-img {
  width: 150px;
}

#screen-img {
  width: 20rem;
}

#img-store {
  height: 40px;
  margin: 0.4rem;
}

#div-bottom {
  position: absolute;
  bottom: 0;
  max-width: 768px;
  display: flex;
  text-align: start;
  line-height: 1.6rem;
  font-size: 0.8rem;
  margin: 15px;
  color: azure;
}

#p {
  max-width: 568px;
  display: flex;
  text-align: start;
  line-height: 2.5rem;
  font-size: 1.4rem;
  margin: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

#mobile-p {
  max-width: 768px;
  display: flex;
  text-align: start;
  line-height: 1.2rem;
  font-size: 0.8rem;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

</style>
